var passiveSupported = false;

try {
  var options = Object.defineProperty({}, 'passive', {
    // eslint-disable-next-line getter-return
    get: function() {
      passiveSupported = true;
    }
  });
  window.addEventListener('test', null, options);
  // eslint-disable-next-line no-empty
} catch (err) {}

export default passiveSupported;
