<template>
  <div>
    <div class="d-block d-lg-none">
      <!-- <label class="titlePicker">{{ title }}</label> -->
      <UIDateTimePicker
        format="DD/MM/YYYY"
        v-model="dateMobile"
        :min-year="minYear"
        :end-date="maxDateMobile"
        :default-selected-value="defaultSelectValue"
        :disabledDates="disabledDates"
        :isClear="isClear"
        @on-change="change"
        @onClearDateTime="onClearDateTime"
      />
    </div>
    <vc-date-picker
      id="input-dob"
      locale="vi"
      :max-date="maxDateDesktop"
      :disabled-dates="disabledDates"
      :min-date="minDateDesktop"
      :input-debounce="500"
      @input="handlerInputCalendar(undefined, 'datetime')"
      class="w-100 d-none d-lg-block"
      v-model="date"
      :popover="{ visibility: 'click', placement: 'top-start' }"
    >
      <input
        data-vv-as="Ngày sinh"
        :class="{ input: true, 'is-danger': errors.has('errorCommon') }"
        :readonly="isMobile ? 'readonly' : undefined"
        v-model="dateText"
        v-mask="'##/##/####'"
        @input="handlerInputCalendar($event, 'input')"
        maxlength="10"
        locale="vi"
        class="form-control"
      />
    </vc-date-picker>
    <label for="date" class="iconTimeDial d-none d-lg-block"></label>
  </div>
</template>
<script>
import UIDateTimePicker from '../../../components/UI/DateTimePicker/UIDateTimePicker';
import { mask } from 'vue-the-mask';
export default {
  components: {
    UIDateTimePicker
  },
  props: {
    date_of_birth: {
      type: null,
      required: true
    },
    isMobile: {
      type: Boolean
    },
    maxDateDesktop: {
      type: null
    },
    maxDateMobile: {
      type: null
    },
    minDateDesktop: {
      type: null
    },
    minYear: Number,
    title: {
      type: String
    },
    disabledDates: {
      type: Object
    },
    isClear: {
      type: Boolean,
      default: false
    },
    defaultSelectValue: String
  },
  data() {
    return {
      dateMobile: '',
      date: null,
      dateText: '',
      isCheckInput: false,
      isCheckedClear: false
    };
  },
  computed: {
    maxYear() {
      return new CustomDate().getFullYear() - 18;
    }
  },
  watch: {
    date_of_birth: function(newVal) {
      if (newVal) {
        this.render(newVal);
      }
    }
  },
  created() {
    // this.$bus.$off('handlerErrorCommon', () => this.handlerError());
    // this.$bus.$on('handlerErrorCommon', ($event) => this.handlerError($event));
  },
  mounted() {
    this.$nextTick(() => {
      this.render(this.date_of_birth);
    });
  },
  directives: { mask },
  methods: {
    render(newVal) {
      this.date = newVal;
      this.dateText = this.date ? this.formatDate(this.date, 'vi') : '';
      this.dateMobile = this.date ? this.formatDate(this.date, 'vi') : '';
    },
    handlerError(event) {
      if (event) {
        this.errors.add({
          field: 'errorCommon',
          msg: event.msg
        });
      }
    },

    handlerInputCalendar($event, key) {
      if (key === 'input') {
        if (!$event || !$event.isTrusted) return;
        if ($event.target.value == '') {
          this.$emit('handlerInputCalendar', '');
        }

        if ($event.target.value && $event.target.value.length === 10) {
          let value = $event.target.value;
          let strDay = new CustomDate(this.formatNewDate(value));
          if (strDay && strDay instanceof Date && !isNaN(strDay.getTime())) {
            this.date = strDay;
            this.$emit('handlerInputCalendar', this.date);
            // this.isCheckInput = true;
          }
        }
      } else {
        if (this.isCheckInput || !this.date) {
          return;
        }
        this.dateText = this.formatDate(this.date, 'vi');
        this.$emit('handlerInputCalendar', this.date);
      }

      // let data = new CustomDate(this.formatDate(this.dateText));
    },
    change() {
      if (window.innerWidth > this.getMobileThresshold()) {
        return;
      }

      this.date = this.dateMobile ? new CustomDate(this.formatNewDate(this.dateMobile)) : '';
      this.$emit('handlerInputCalendar', this.date);
    },
    onClearDateTime() {
      this.dateMobile = '';
      this.date = '';
      this.dateText = '';
    },
    formatNewDate(date) {
      if (typeof date !== 'string') {
        return;
      }
      let arr = date.split('/'),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return [month, day, year].join('/');
    },
    formatDate(date, type) {
      if (type == 'vi') {
        var d = new CustomDate(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [day, month, year].join('/');
      } else {
        return (
          date &&
          date
            .split('/')
            .reverse()
            .toString()
            .replaceAll(',', '/')
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.iconTimeDial {
  background-image: url('../../../assets/img/icon/icon_calendar.svg');
  width: 30px;
  height: 40px;
  background-repeat: no-repeat;
  position: absolute;
  right: 3px;
  top: 12px;
  cursor: pointer;
}
</style>
