<template>
  <div>
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Vé đã mua</div>
      <div class="header-link">
        <!-- <router-link to="/tai-khoan/tim-ve/" v-if="userInfo.shop_data"
          ><img class="mr-1" src="@/assets/img/icon_search-muted.svg" alt="" /> Tìm vé</router-link
        >-->
      </div>
    </div>
    <hr class="d-none d-lg-block" />
    <div class="section-content flex-column">
      <div class="sub-section" v-if="vipInfo && vipInfo.is_shop">
        <div class="sub-section__header">
          <span class="sub-section__title">Thông tin điểm bán</span>
          <!-- <span class="sub-section__action">Cập nhật</span> -->
        </div>
        <b-card class="content-card">
          <div class="shop-info">
            <div class="shop-info__title">TÊN ĐIỂM BÁN</div>
            <div class="shop-info__value">{{ vipInfo.shop_name }}</div>
          </div>
          <div class="shop-info">
            <div class="shop-info__title">SỐ ĐIỆN THOẠI</div>
            <div class="shop-info__value">{{ vipInfo.shop_phone }}</div>
          </div>
          <div class="shop-info">
            <div class="shop-info__title">ĐỊA CHỈ</div>
            <div class="shop-info__value">{{ vipInfo.shop_address }}</div>
          </div>
        </b-card>
      </div>
      <div class="sub-section">
        <div class="sub-section__header">
          <span class="sub-section__title">Báo cáo</span>
          <div class="sub-section__filter">
            <div class="row">
              <div class="col-sm-12 col-md-1 d-md-block d-none"></div>
              <div class="col-6 col-md-3">
                <b-form-group class="w-100" id="input-from-date" v-if="filterListActived.key == 0">
                  <UIDateTimePickerCommon
                    :isMobile="isMobile"
                    :title="'Ngày sinh'"
                    :date_of_birth="dateFrom"
                    :maxDateDesktop="maxDateDesktopFrom"
                    :maxDateMobile="maxDateMobileFrom"
                    :minYear="1920"
                    :minDateDesktop="new CustomDate(vipInfo && vipInfo.valid_from ? vipInfo.valid_from : '1920')"
                    :defaultSelectValue="new CustomDate().toString()"
                    @handlerInputCalendar="handlerInputFrom"
                  ></UIDateTimePickerCommon>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group class="w-100" id="input-to-date" v-if="filterListActived.key == 0">
                  <UIDateTimePickerCommon
                    :isMobile="isMobile"
                    :title="'Ngày sinh'"
                    :date_of_birth="dateTo"
                    :maxDateDesktop="maxDateDesktop"
                    :maxDateMobile="maxDateMobile"
                    :minYear="1920"
                    :minDateDesktop="new CustomDate(dateFrom)"
                    :defaultSelectValue="new CustomDate().toString()"
                    @handlerInputCalendar="handlerInputTo"
                  ></UIDateTimePickerCommon>
                </b-form-group>
              </div>
              <div class="col-6 col-md-2">
                <b-button class="btnFilter" block v-if="filterListActived.key == 0" @click="getShopSummaryWithFilter"
                  >XÁC NHẬN</b-button
                >
              </div>
              <!-- <div :class="(filterListActived.key == 0 ? 'col-6' : 'col-12') + ' col-md-3'">
                <MaxGameDropdownPlayList
                  :data="filterList"
                  :isOpen="isOpenFilterList"
                  @updateData="updateDataFilterList"
                  :itemActived="filterListActived"
                  @updateModal="updateModalFilterList"
                  :isLoading="false"
                />
              </div> -->
            </div>
          </div>
        </div>
        <b-card>
          <div class="content-card">
            <div class="shop-info">
              <div class="shop-info__title">THỜI GIAN BẮT ĐẦU</div>
              <div class="shop-info__value">
                {{ formatDate(vipInfo ? vipInfo.valid_from : new CustomDate(), 'DD/MM/YYYY') }}
              </div>
            </div>
          </div>
          <div class="content-card mt-3" v-if="shopDeposit">
            <div class="shop-info">
              <div class="shop-info__title">TỔNG NẠP TIỀN</div>
              <div class="shop-info__value">{{ formatPrice(shopDeposit.total || 0) }}</div>
            </div>
            <div class="shop-info">
              <div class="shop-info__title">TỈ LỆ THƯỞNG</div>
              <div class="shop-info__value">{{ formatPrice(shopDeposit.rate * 100, '%') }}</div>
            </div>
            <div class="shop-info">
              <div class="shop-info__title">TỔNG THƯỞNG</div>
              <div class="shop-info__value">{{ formatPrice(shopDeposit.commission || 0) }}</div>
            </div>
          </div>
          <div class="content-card mt-3" v-if="shopRevenue">
            <div class="shop-info">
              <div class="shop-info__title">TỔNG TIỀN MUA VÉ</div>
              <div class="shop-info__value">{{ formatPrice(shopRevenue.total || 0) }}</div>
            </div>
            <div class="shop-info">
              <div class="shop-info__title">TỈ LỆ HOA HỒNG</div>
              <div class="shop-info__value">{{ formatPrice(shopRevenue.rate * 100, '%') }}</div>
            </div>
            <div class="shop-info">
              <div class="shop-info__title">TỔNG HOA HỒNG</div>
              <div class="shop-info__value">{{ formatPrice(shopRevenue.commission || 0) }}</div>
            </div>
          </div>
        </b-card>
        <!-- <div class="sub-section__header">
          <div class="sub-section__filter">
            <div calss="row">
              <div class="ml-auto col-12 col-md-3  mt-3">
                <MaxGameDropdownPlayList
                  :data="filterDeposit"
                  :isOpen="isOpenFilterDeposit"
                  @updateData="updateDataFilterDeposit"
                  :itemActived="filterDepositActived"
                  @updateModal="updateModalFilterDeposit"
                  :isLoading="false"
                />
              </div>
            </div>
          </div>
        </div> -->
        <b-table-simple
          small
          responsive
          bordered
          class="data-table text-center mt-2"
          v-if="shopReferral && (showReferral || showFriend)"
        >
          <b-thead>
            <b-tr>
              <b-th>Tài khoản</b-th>
              <b-th v-if="showReferral">Tổng nạp</b-th>
              <b-th v-if="showReferral">Doanh thu</b-th>
              <b-th v-if="showReferral">Hoa hồng ({{ formatPrice(shopReferral.referralRate * 100, '%') }})</b-th>
              <b-th v-if="showReferral">Tổng vé</b-th>
              <b-th v-if="showFriend">Số bạn bè</b-th>
              <b-th v-if="showFriend">Doanh thu mời bạn</b-th>
              <b-th v-if="showFriend">Hoa hồng thêm ({{ formatPrice(shopReferral.friendRate * 100, '%') }})</b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="rows > 0">
            <tr v-for="(item, index) in items" :key="'row_' + index">
              <b-td v-if="item.name">
                <strong>{{ item.name }}</strong>
                <br />
                {{ item.username }}
              </b-td>
              <b-td v-else>{{ item.username }}</b-td>
              <b-td v-if="showReferral">{{ formatPrice(item.total_deposit || 0) }}</b-td>
              <b-td v-if="showReferral">{{ formatPrice(item.total_revenue || 0) }}</b-td>
              <b-td v-if="showReferral">{{ formatPrice(item.total_commission || 0) }}</b-td>
              <b-td v-if="showReferral">{{ item.total_ticket || 0 }}</b-td>
              <b-td v-if="showFriend">{{ item.total_invited || 0 }}</b-td>
              <b-td v-if="showFriend">{{ formatPrice(item.friend_revenue || 0) }}</b-td>
              <b-td v-if="showFriend">{{ formatPrice(item.friend_commission || 0) }}</b-td>
            </tr>
            <tr class="success">
              <b-td>
                <strong>Tổng cộng</strong>
              </b-td>
              <b-td v-if="showReferral">{{ formatPrice(shopReferral.total_deposit || 0) }}</b-td>
              <b-td v-if="showReferral">{{ formatPrice(shopReferral.total_referral || 0) }}</b-td>
              <b-td v-if="showReferral">{{ formatPrice(shopReferral.total_referral_cms || 0) }}</b-td>
              <b-td v-if="showReferral">{{ shopReferral.total_ticket || 0 }}</b-td>
              <b-td v-if="showFriend">{{ shopReferral.total_friend_invited || 0 }}</b-td>
              <b-td v-if="showFriend">{{ formatPrice(shopReferral.total_friend_revenue || 0) }}</b-td>
              <b-td v-if="showFriend">{{ formatPrice(shopReferral.total_friend_cms || 0) }}</b-td>
            </tr>
          </b-tbody>

          <b-tbody v-else>
            <tr>
              <b-td colspan="8" class="text-center">Không có dữ liệu</b-td>
            </tr>
          </b-tbody>
        </b-table-simple>
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          pills
          hide-ellipsis
          first-number
          last-number
          hide-goto-end-buttons
          :per-page="perPage"
          :total-rows="rows"
          @input="getShopSummary()"
          next-class="d-none"
          prev-class="d-none"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@media (max-width: 425px) {
  .sub-section {
    &__header {
      align-items: flex-start !important;
      flex-direction: column;
    }
    &__filter {
      width: 100%;
      button,
      .mDropdown {
        margin-top: 10px;
      }
      .btnFilter {
        max-width: none !important;
      }
      .weui-cell {
        background-color: #fff;
        border-color: #fff;
        margin-top: 10px;
      }
    }
    .content-card .card-body,
    .content-card {
      flex-direction: column !important;
    }
    .content-card {
      .shop-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__title {
          font-size: 15px !important;
          flex: 1;
        }
        &__value {
          text-align: right;
          padding-left: 10px;
        }
      }
    }
  }
}
.sub-section {
  margin-bottom: 20px;
  &__header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.1;
    text-align: left;
    color: #666;
  }
  &__action {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 1.47 !important;
    color: #666 !important;
    cursor: pointer;
  }
  &__filter {
    flex: 1;
    text-align: right;
    font-size: 15px;
    .btnChooseMoney {
      padding-left: 15px;
      &:after {
        right: 15px;
      }
    }
    .row {
      margin-left: -5px;
      margin-right: -5px;
      div[class^='col-'] {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .btnFilter {
      background-color: #d31904;
      border-color: #d31904;
      color: #fff;
      border-radius: 3px;
      padding: 7px 0;
      width: 100%;
      max-width: 157px;
      font-weight: 600;
      font-size: 0.9375rem;
      &:focus {
        outline: 0;
        background-color: #a11303;
        border-color: #a11303;
      }
      &:hover {
        background-color: #a11303;
        border-color: #a11303;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    .form-control {
      border: #fff;
      background-color: #fff;
      &:focus {
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
    .mDropdown > div {
      margin-top: 0 !important;
    }
  }
  .content-card .card-body,
  .content-card {
    display: flex;
    flex-direction: row;
    .shop-info {
      flex: 1;
      padding: 0 5px;
      &__title {
        font-size: 14px;
        color: #666;
        opacity: 0.85;
      }
      &__value {
        font-size: 15px;
        font-weight: 600;
        color: #333;
      }
    }
  }
  .page-item.active > button {
    background-color: $primaryColor;
    -webkit-box-shadow: none;
    box-shadow: none;
    // color: #0d234a;
  }
  .page-item > .page-link {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-weight: 600;
  }

  .page-item > button {
    background-color: transparent;
    color: #0d234a;
    border: none;
    font-weight: 600;
  }
}
.data-table {
  td,
  th {
    background-color: #fff;
    vertical-align: middle !important;
    border-color: #f7f7f7;
    border-bottom: 1px solid #f7f7f7 !important;
  }

  td {
    padding: 8px 5px;
  }

  th,
  tr:last-child > td {
    padding: 13px 5px;
  }
}
</style>

<script>
import AccountService from '@/api/account';
// import MaxGameDropdownPlayList from '../../MaxGame/MaxGameDropdownPlayList';
import UIDateTimePickerCommon from '../../UI/DateTimePicker/UIDateTimePickerCommon';

export default {
  layouts: 'ShopSummary',
  components: { UIDateTimePickerCommon },
  data() {
    return {
      items: [],
      rows: 0,
      perPage: 20,
      currentPage: 1,
      filterDeposit: [
        { key: '', value: 'Tất cả', isActive: true },
        { key: 'paid', value: 'Đã nạp', isActive: true },
        { key: 'unpaid', value: 'Chưa nạp', isActive: true }
      ],
      filterList: [
        { key: 1, value: 'Hôm nay', isActive: true },
        { key: 7, value: '7 ngày gần nhất', isActive: true },
        { key: 30, value: '30 ngày gần nhất', isActive: true },
        { key: 0, value: 'Tuỳ chỉnh', isActive: true }
      ],
      isOpenFilterList: false,
      isOpenFilterDeposit: false,
      filterListActived: { key: 1, value: 'Hôm nay', isActive: true },
      filterDepositActived: { key: '', value: 'Tất cả', isActive: true },
      from: null,
      to: null,
      dateFrom: new CustomDate().toString(),
      dateTo: new CustomDate().toString(),
      shopDeposit: null,
      shopRevenue: null,
      shopReferral: false,
      validFrom: new CustomDate()
    };
  },
  watch: {
    filterListActived: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal.key !== oldVal.key) {
          this.getDateFromTo(newVal.key);
          if (newVal.key !== 0) this.getShopSummary();
        }
      }
    },
    filterDepositActived: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal.key !== oldVal.key) {
          this.getShopSummary();
        }
      }
    }
  },
  created() {
    if (
      typeof this.$store.getters.getterUserInfo.vip_data !== 'undefined' &&
      !this.$store.getters.getterUserInfo.vip_data
    ) {
      this.$router.push('/');
    } else {
      this.getShopSummary();
    }
  },
  mounted() {},
  methods: {
    handlerInputFrom(newVal) {
      this.dateFrom = newVal;
    },
    handlerInputTo(newVal) {
      this.dateTo = newVal;
    },
    getOnlyDate(date) {
      return new CustomDate(date.toDateString()).toISOString();
    },
    getDateFromTo(day) {
      let today = new CustomDate();
      if (day === 0) {
        this.from = this.getOnlyDate(today);
        this.to = this.getOnlyDate(new CustomDate(today.setDate(today.getDate() + 1)));
      } else {
        this.to = this.getOnlyDate(new CustomDate(today.setDate(today.getDate() + 1)));
        this.from = this.getOnlyDate(new CustomDate(today.setDate(today.getDate() - day)));
      }
    },
    updateModalFilterDeposit(value) {
      this.isOpenFilterDeposit = value;
    },
    updateDataFilterDeposit(newObj) {
      this.filterDepositActived = newObj;
    },
    updateModalFilterList(value) {
      this.isOpenFilterList = value;
    },
    updateDataFilterList(newObj) {
      this.filterListActived = newObj;
    },
    getShopSummaryWithFilter() {
      this.from = this.getOnlyDate(new CustomDate(this.dateFrom));
      let dateTo = new CustomDate(this.dateTo);
      dateTo.setDate(dateTo.getDate() + 1);
      this.to = this.getOnlyDate(new CustomDate(dateTo));
      this.getShopSummary();
    },
    getShopSummary() {
      if (this.filterListActived.key !== 0) {
        this.getDateFromTo(this.filterListActived.key);
      }
      let filterDeposit = {};
      if (this.filterDepositActived.key) {
        filterDeposit = {
          deposit: this.filterDepositActived.key
        };
      }
      AccountService.getShopSummary(this.accountId, {
        limit: this.perPage,
        offset: this.currentPage,
        from: this.from,
        to: this.to,
        ...filterDeposit
      })
        .then((resp) => {
          if (resp.data && resp.data.status) {
            const { info, summary } = resp.data.data;
            if (summary) {
              const { deposit, referral, revenue } = summary;
              this.shopDeposit = deposit;
              this.shopRevenue = revenue;
              if (referral && (referral.friend_cms_rate > 0 || referral.referral_cms_rate > 0)) {
                this.rows = referral.count;
                this.items = [...referral.data];
                this.shopReferral = {
                  referralRate: referral.referral_cms_rate,
                  friendRate: referral.friend_cms_rate,
                  ...referral.total
                };
              }
            }
            this.vipInfo = { ...info, is_shop: this.vipInfo && this.vipInfo.is_shop };
          }
        })
        .catch(() => {
          this.$router.push('/');
        })
        .finally(() => {});
    }
  },
  computed: {
    showReferral() {
      return this.shopReferral.referralRate > 0 || this.shopReferral.total_referral_cms > 0;
    },
    showFriend() {
      return this.shopReferral.friendRate > 0 || this.shopReferral.total_friend_cms > 0;
    },
    isMobile() {
      return window.innerWidth <= this.getMobileThresshold();
    },
    accountId() {
      return this.$store.getters.getterUserProfile.id;
    },
    vipInfo: {
      get() {
        return this.$store.getters.getterUserInfo.vip_data;
      },
      set(newVal) {
        this.$store.commit('setUserInfo', { ...this.$store.getters.getterUserInfo, vip_data: newVal });
      }
    },
    maxDateMobile() {
      var d = new CustomDate(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    },
    maxDateDesktop() {
      let d = new CustomDate();
      return new CustomDate(d.getFullYear(), d.getMonth(), d.getDate());
    },
    maxDateMobileFrom() {
      var d = new CustomDate(this.dateTo),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    },
    maxDateDesktopFrom() {
      let d = new CustomDate(this.dateTo);
      return new CustomDate(d.getFullYear(), d.getMonth(), d.getDate());
    }
  }
};
</script>
