<template>
  <div>
    <a
      class="vux-datetime weui-cell"
      :class="{ 'weui-cell_access': !readonly }"
      :data-cancel-text="'Huỷ'"
      :data-confirm-text="'Xong'"
      href="javascript:"
    >
      <slot>
        <div
          class="weui-cell__ft vux-cell-primary vux-datetime-value"
          :class="{ 'vux-datetime-padding': isClear }"
          :style="{
            textAlign: valueTextAlign
          }"
        >
          <span class="vux-cell-placeholder" v-if="!currentValue && placeholder">{{ placeholder }}</span>
          <span class="vux-cell-value" v-if="currentValue">{{
            displayFormat ? displayFormat(currentValue) : currentValue
          }}</span>
          <!-- <icon class="vux-input-icon" type="warn" v-show="!valid" :title="firstError"></icon> -->
        </div>
      </slot>
    </a>
    <a @click="handlerClearDateTime" v-if="isClear" :class="{ clearDatetime: isClear }">
      <img src="../../../assets/img/icon_menu_close.svg" width="15" height="15" />
    </a>
  </div>
</template>
<script>
// import Icon from '../icon';
import Picker from '@/js/dateTimePicker/datetimepicker';
// import Group from '../group';
// import InlineDesc from '../inline-desc';
// import Uuid from '../../mixins/uuid';
import format from '@/js/dateTimePicker/format';
import { APP_ENV } from '../../../common/global';

export default {
  name: 'datetime',
  // mixins: [Uuid],
  components: {
    // Group
    // InlineDesc
    // Icon
  },
  props: {
    format: {
      type: String,
      default: 'YYYY-MM-DD',
      validator(val) {
        /* istanbul ignore if */
        if (APP_ENV === 'development' && val && /A/.test(val) && val !== 'YYYY-MM-DD A') {
          return console.error('[VUX] Datetime prop:format 使用 A 时只允许的值为： YYYY-MM-DD A');
        }
        return true;
      }
    },
    disabledDates: {
      type: Object
    },
    title: String,
    value: {
      type: null,
      required: true
    },
    inlineDesc: String,
    placeholder: String,
    minYear: Number,
    maxYear: Number,
    confirmText: String,
    cancelText: String,
    clearText: {
      type: String,
      default: 'delete'
    },
    yearRow: {
      type: String,
      default: '{value}'
    },
    monthRow: {
      type: String,
      default: '{value}'
    },
    dayRow: {
      type: String,
      default: '{value}'
    },
    hourRow: {
      type: String,
      default: '{value}'
    },
    minuteRow: {
      type: String,
      default: '{value}'
    },
    required: {
      type: Boolean,
      default: false
    },
    minHour: {
      type: Number,
      default: 0
    },
    maxHour: {
      type: Number,
      default: 23
    },
    isClear: {
      type: Boolean,
      default: false
    },
    startDate: {
      type: String,
      validator(val) {
        /* istanbul ignore if */
        if (APP_ENV === 'development' && val && val.length !== 10) {
          console.error('[VUX] Datetime prop:start-date 必须为 YYYY-MM-DD 格式');
        }
        return val ? val.length === 10 : true;
      }
    },
    endDate: {
      type: String,
      validator(val) {
        /* istanbul ignore if */
        if (APP_ENV === 'development' && val && val.length !== 10) {
          console.error('[VUX] Datetime prop:end-date 必须为 YYYY-MM-DD 格式');
        }
        return val ? val.length === 10 : true;
      }
    },
    valueTextAlign: String,
    displayFormat: Function,
    readonly: Boolean,
    hourList: Array,
    minuteList: Array,
    show: Boolean,
    defaultSelectedValue: String,
    computeHoursFunction: Function,
    computeDaysFunction: Function,
    orderMap: Object
  },
  created() {
    this.isFirstSetValue = false;
    this.currentValue = this.value;
  },
  data() {
    return {
      currentShow: false,
      currentValue: null,
      valid: true,
      uuid: Math.random()
        .toString(36)
        .substring(3, 8)
      // errors: {}
    };
  },
  mounted() {
    const uuid = this.uuid;
    this.$el.getElementsByClassName('vux-datetime')[0].setAttribute('id', `vux-datetime-${uuid}`);
    // this.$el.setAttribute('id', `vux-datetime-${uuid}`);
    if (!this.readonly) {
      this.$nextTick(() => {
        this.render();
        if (this.show) {
          this.$nextTick(() => {
            this.picker && this.picker.show(this.currentValue);
          });
        }
      });
    }
  },
  computed: {
    styles() {
      if (!this.$parent) {
        return {};
      }
      return {
        width: this.$parent.labelWidth,
        textAlign: this.$parent.labelAlign,
        marginRight: this.$parent.labelMarginRight
      };
    },
    pickerOptions() {
      const _this = this;
      const options = {
        trigger: '#vux-datetime-' + this.uuid,
        format: this.format,
        value: this.currentValue,
        output: '.vux-datetime-value',
        confirmText: _this.getButtonText('confirm'),
        cancelText: _this.getButtonText('cancel'),
        clearText: _this.clearText,
        yearRow: this.yearRow,
        monthRow: this.monthRow,
        dayRow: this.dayRow,
        hourRow: this.hourRow,
        minuteRow: this.minuteRow,
        minHour: this.minHour,
        maxHour: this.maxHour,
        startDate: this.startDate,
        disabledDates: this.disabledDates,
        endDate: this.endDate,
        hourList: this.hourList,
        minuteList: this.minuteList,
        defaultSelectedValue: this.defaultSelectedValue,
        computeHoursFunction: this.computeHoursFunction,
        computeDaysFunction: this.computeDaysFunction,
        orderMap: this.orderMap || {},
        onSelect(type, val, wholeValue) {
          if (_this.picker && _this.picker.config.renderInline) {
            _this.$emit('input', wholeValue);
            _this.$emit('on-change', wholeValue);
          }
        },
        onConfirm(value) {
          _this.currentValue = value;
        },
        onClear(value) {
          _this.$emit('on-clear', value);
        },
        onHide(type) {
          _this.currentShow = false;
          _this.$emit('update:show', false);
          _this.validate();
          _this.$emit('on-hide', type);
          if (type === 'cancel') {
            _this.$emit('on-cancel');
          }
          if (type === 'confirm') {
            // eslint-disable-next-line vue/no-async-in-computed-properties
            setTimeout(() => {
              _this.$nextTick(() => {
                _this.$emit('on-confirm', _this.value);
              });
            });
          }
        },
        onShow() {
          _this.currentShow = true;
          _this.$emit('update:show', true);
          _this.$emit('on-show');
        }
      };
      if (this.minYear) {
        options.minYear = this.minYear;
      }
      if (this.maxYear) {
        options.maxYear = this.maxYear;
      }
      return options;
    },
    // firstError() {
    //   let key = Object.keys(this.errors)[0];
    //   return this.errors[key];
    // },
    labelClass() {
      if (!this.$parent) {
        return {};
      }
      return {
        'vux-cell-justify': this.$parent.labelAlign === 'justify' || this.$parent.$parent.labelAlign === 'justify'
      };
    }
  },
  methods: {
    // formatDate(date, type) {
    //   if (type == 'vi') {
    //     var d = new CustomDate(date),
    //       month = '' + (d.getMonth() + 1),
    //       day = '' + d.getDate(),
    //       year = d.getFullYear();

    //     if (month.length < 2) month = '0' + month;
    //     if (day.length < 2) day = '0' + day;
    //     return [day, month, year].join('-');
    //   } else {
    //     return (
    //       date &&
    //       date
    //         .split('-')
    //         .reverse()
    //         .toString()
    //         .replaceAll(',', '-')
    //     );
    //   }
    // },
    handlerClearDateTime() {
      this.$emit('onClearDateTime');
    },
    getButtonText(type) {
      if (type === 'cancel' && this.cancelText) {
        return this.cancelText;
      } else if (type === 'confirm' && this.confirmText) {
        return this.confirmText;
      }
      return this.$el.getElementsByClassName('vux-datetime')[0].getAttribute(`data-${type}-text`);
    },
    render() {
      this.$nextTick(() => {
        this.picker && this.picker.destroy();
        this.picker = new Picker(this.pickerOptions);
      });
    },
    validate() {
      if (!this.currentValue && this.required) {
        this.valid = false;
        // this.errors.required = '必填';
        return;
      }
      this.valid = true;
      // this.errors = {};
    }
  },
  watch: {
    readonly(val) {
      if (val) {
        this.picker && this.picker.destroy();
      } else {
        this.render();
      }
    },
    show(val) {
      if (val === this.currentShow) return;
      if (val) {
        this.picker && this.picker.show(this.currentValue);
      } else {
        this.picker && this.picker.hide(this.currentValue);
      }
    },
    currentValue(val, oldVal) {
      // let data = new CustomDate(this.formatDate(val));
      this.$emit('input', val);
      if (!this.isFirstSetValue) {
        this.isFirstSetValue = true;
        oldVal && this.$emit('on-change', val);
      } else {
        this.$emit('on-change', val);
      }
      this.validate();
    },
    startDate() {
      this.render();
    },
    endDate() {
      this.render();
    },
    format(val) {
      if (this.currentValue) {
        this.currentValue = format(this.currentValue, val);
      }
      this.render();
    },
    value(val) {
      // do not force render when renderInline is true
      if (this.readonly || (this.picker && this.picker.config.renderInline)) {
        this.currentValue = val;
        return;
      }
      if (this.currentValue !== val) {
        this.currentValue = val;
        this.render();
      }
    }
  },
  beforeDestroy() {
    this.picker && this.picker.destroy();
  }
};
</script>
<style lang="scss">
.vux-datetime-padding {
  padding-right: 2rem;
}
.clearDatetime {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 38px;
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.weui-cell_access .weui-cell__ft {
  padding-left: 13px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.weui-cell_access {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: inherit;
}
.vux-datetime {
  color: #333;
  &:hover {
    color: #333;
  }
}
.weui-cell {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #efefef;
  background-color: #f7f7f7;
  display: block;
}
.vux-label-desc {
  font-size: 14px;
  color: #666;
}
.dp-container {
  &.vux-datetime-view {
    position: static;
    transition: none;
    & .dp-header {
      display: none;
    }
  }
}

.vux-datetime-clear {
  text-align: center;
}

.scroller-component {
  display: block;
  position: relative;
  height: 238px;
  overflow: hidden;
  width: 100%;
}

.scroller-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.scroller-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)),
    linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
  background-position: top, bottom;
  background-size: 100% 102px;
  background-repeat: no-repeat;
}

.scroller-item {
  text-align: center;
  font-size: 18px;
  height: 36px;
  line-height: 24px;
  color: #000;
}

.scroller-indicator {
  width: 100%;
  height: 36px;
  position: absolute;
  left: 0;
  top: 102px;
  z-index: 3;
  background-image: linear-gradient(to bottom, #d0d0d0, #d0d0d0, transparent, transparent),
    linear-gradient(to top, #d0d0d0, #d0d0d0, transparent, transparent);
  background-position: top, bottom;
  background-size: 100% 1px;
  background-repeat: no-repeat;
}

.dp-container {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 10000;
  background-color: #fff;
  display: none;
  transition: transform 0.3s ease;
  transform: translateY(100%);
}

.dp-mask {
  z-index: 998;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  background-color: #000;
  z-index: 9999;
}

.dp-header {
  display: flex;
  width: 100%;
  box-align: center;
  align-items: center;
  background-image: linear-gradient(to bottom, #e7e7e7, #e7e7e7, transparent, transparent);
  background-position: bottom;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  justify-content: space-between;
}

.dp-header .dp-item {
  color: #ff9900;
  font-size: 16px;
  height: 44px;
  line-height: 44px;
  flex: 0 0 25%;
  cursor: pointer;
  &.vux-message {
    color: #333333;
    flex: 0 0 50%;
    text-align: center;
  }
}

.dp-header .dp-item.dp-left {
  text-transform: uppercase;
  color: #333333;
}

.dp-header .dp-item.dp-right {
  text-transform: uppercase;
  color: #ff9900;
}

.dp-content {
  display: flex;
  width: 100%;
  box-align: center;
  align-items: center;
  padding: 10px 0;
}

.dp-header .dp-item,
.dp-content .dp-item {
  box-sizing: border-box;
  flex: 1;
}
.vux-datetime-cancel {
  text-align: left;
  padding-left: 15px;
}
.vux-datetime-confirm {
  text-align: right;
  padding-right: 15px;
  &.disable-confirm {
    color: #666666 !important;
  }
}
.vux-datetime {
  color: #000;
  text-decoration: none;
}
.vux-datetime .vux-input-icon {
  float: right;
}
.vux-cell-primary {
  flex: 1;
}
</style>
